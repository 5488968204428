import { useState, useEffect, useCallback } from 'react';
import useWebSocket from './useWebSocket';
import { Img } from 'components';

const useChat = () => {
  const [currentMessage, setCurrentMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [images, setImages] = useState([]);
  const [countMessagesIterated, setCountMessagesIterated] = useState(0);
  const [mediaCalled, setMediaCalled] = useState(0);

  const { 
    handleSendMessage, 
    messageQueue, 
    isTyping, 
    setMessageQueue, 
    setIsTyping, 
    isConnected, 
    setIsConnected,
    error
  } = useWebSocket();

  const processMessageQueue = useCallback(() => {
    setCountMessagesIterated(prev => prev + 1);
    if (messageQueue.length > 0) {
      const nextChunk = messageQueue.shift();
      if (nextChunk === "[*/end-of-response]") {
        finishMessage();
      } else {
        try {
          const content = JSON.parse(nextChunk);
          if (content['media-content']) {
            setImages(prev => [...prev, content['media-content']]);
            setCurrentMessage(prev => prev + content['message']);
            setTimeout(() => {
              setMediaCalled(count => count + 1);
            }, 1000);
          } else {
            setCurrentMessage(prev => prev + nextChunk);
          }
        } catch (e) {
          // If it's not JSON, treat it as plain text
          setCurrentMessage(prev => prev + nextChunk);
        }
      }
      setMessageQueue([...messageQueue]);
    }
  }, [messageQueue, setMessageQueue]);

  const finishMessage = useCallback(() => {
    setIsTyping(false);
    setCountMessagesIterated(0);
    setMessages(prevMessages => [
      ...prevMessages,
      {
        sender: process.env.REACT_APP_AGENT_NAME,
        type: 'text',
        text: currentMessage,
        avatar: <Img src="images/servicio-al-cliente-mujer.svg" alt="Executive" width="50" height="60" className={"min-w-[50px]"} />,
        time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }
    ]);
    setCurrentMessage('');
  }, [currentMessage, setIsTyping]);

  useEffect(() => {
    let timer;
    if (messageQueue.length > 0 && isTyping) {
      timer = setInterval(processMessageQueue, 100);
    }
    return () => clearInterval(timer);
  }, [messageQueue, isTyping, processMessageQueue]);

  return { 
    isConnected, 
    messages, 
    setMessages, 
    handleSendMessage, 
    isTyping, 
    countMessagesIterated,
    currentMessage, 
    error,
    images,
    mediaCalled
  };
};

export default useChat;