import React, { useState, useEffect } from "react";
import { Img, Text, Heading, TextArea, Button, CustomIcons } from "components";
import validate from '../../utils/validate';
import LayoutView from "pages/layout";
import { useNavigate } from 'react-router-dom';

export default function PageHome() {
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [errors, setErrors] = useState([{}]);
  const navigate = useNavigate();

  useEffect(() => {  
    if (isPageLoading === true) {
      setTimeout(() => {
        setIsPageLoading(false);
      }, 500);
    }
  }, []);

  const rules = {
    question: 'required',
  };

  const handleSubmit = async (e) => {
    setIsPageLoading(true);
    const formData = { question: document.getElementById('question').value };
    await validate.rules(formData, rules)
    .then(async () => {
      try {
        setTimeout(() => {
          setErrors([]);
          setIsPageLoading(false);
          navigate(`/conversation`, { state: { question: formData.question} });
        }, 2000);
      } catch (error) {
        console.error('Error al enviar formulario', error);
      }
    })
    .catch((errorObjects) => {
      setIsPageLoading(false);
      const formattedErrors = [];
      errorObjects.forEach(error => {
        (error.message !== undefined && error.message !== null) &&
        formattedErrors.push(error.message);
      });
      setErrors(formattedErrors);
    });
  };

  return LayoutView(
    <>
      <div className="flex w-[48%] flex-col items-center justify-center gap-3 sm:gap-3 md:gap-4 px-14 sm:w-full md:w-full sm:h-full sm:p-5">
        <div className="flex w-full flex-col items-center gap-4 p-2 sm:w-full">
          <Img src="images/servicio-al-cliente-mujer.svg" alt="Executive" className="h-[80px] w-[80px]" />
          <div className="flex w-[88%] flex-col gap-2 items-center justify-center sm:w-full md:w-full">
            <Text size="textxs" as="p" className="tracking-[0.15px] !text-black-900_dd sm:text-[18px] md:text-[24px] lg:text-[24px]">
              Vas a ser atendido por
            </Text>
            <Heading as="h1" className="tracking-[0.25px] sm:text-[18px] md:text-[24px] lg:text-[24px]">
              {`${process.env.REACT_APP_AGENT_NAME}`}
            </Heading>
            <Img
              src="images/logo.png"
              alt="Settings"
              className="mx-[28px] h-[55px] sm:h-[40px] md:h-[55px] w-auto sm:mx-0"
            />
          </div>
        </div>
        <div className="flex w-[88%] justify-center rounded-[16px] border border-solid border-black-900_3a bg-white-a700 px-3 sm:w-full md:w-full md:px-1">
          <div className="flex w-full items-center justify-between">
            <TextArea 
              wrapClassName="w-full"
              className="self-end tracking-[0.15px] w-full sm:h-auto md:h-[32px] lg:h-auto"
              type="text"
              placeholder="Escribe aqui tu consulta..."
              name="question"
              id="question"
              onKeyPress={(e) => { 
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit();
                } 
              }}
              autoFocus
              required
            />
            {!isPageLoading ?
              <Button 
                className=""
                onClick={handleSubmit}
                {...(isPageLoading && { disabled: true })}
              >
                <Img src="images/img_adornmentend.svg" alt="Adornmentend" className="h-[24px] w-[24px]" />
              </Button>
            : <CustomIcons iconName="wired-outline-74-navigation" backgroundColor="bg-white-a700" size={35} />}
          </div>
        </div>
      </div>
      <div className="flex-1 sm:self-stretch sm:p-5 sm:hidden">
        <Img
          src="images/main.jpeg"
          alt="Container"
          className="h-screen w-full object-cover"
        />
      </div>
    </>
  );
}
